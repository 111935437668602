import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Zwischensfinanzierung = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Zwischenfinanzierung – Ein Überblick" showCalc={false} />
            <Article>
                <p>
                    Bei miracl verfolgen wir ein doppeltes Ziel: Wir wollen dir nicht nur eine Immobilienfinanzierung zu
                    den besten Konditionen verschaffen, sondern auch unser Finanzierungswissen weitergeben. Denn nur so
                    kannst du selbst einschätzen, welches Angebot tatsächlich am besten zu dir passt. Im folgenden
                    Artikel wagen wir uns bis zum Ende des Alphabets vor und sehen uns die Zwischenfinanzierung an.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was ist eine Zwischenfinanzierung?</h2>
                <p>
                    Bei der Zwischenfinanzierung handelt es sich um eine Kreditart, die dir dabei hilft, das Fehlen von
                    Eigenkapitalmengen, die du erst zu einem späteren Zeitpunkt erhalten wirst, zu überbrücken. Das kann
                    beispielsweise notwendig sein, wenn du eine neue Immobilie kaufen möchtest, für die die alte aber
                    noch keine Käufer:in gefunden hast.
                </p>
                <p>
                    Sehen wir uns ein Beispiel an: Stellen wir uns vor, deine derzeitige Eigentumswohnung, die von
                    Schulden unbelastet ist, hat einen Wert von 400 000 Euro. Aufgrund eines Jobwechsels siehst du dich
                    genötigt, eine andere Stadt zu ziehen, in der du auch schon ein passendes Objekt gefunden hast. Die
                    Wohnung, die du dort ins Auge gefasst hast, würde dich 350 000 Euro kosten. Um deine aktuelle
                    Immobilie nicht voreilig verkaufen und unter Umständen ein zu niedriges Angebot annehmen zu müssen,
                    kannst du auf eine Zwischenfinanzierung zurückgreifen. Dein Kapital ist ja schließlich in einer
                    Immobilie gebunden, die dir noch gehört und die folglich als Sicherheit verwendet werden kann. Es
                    steht dir dabei frei, ob du den gesamten Verkaufserlös oder nur einen Teil davon für die
                    Zwischenfinanzierung verwendest. Nutzt du den gesamten Betrag, so kannst du die Zwischenfinanzierung
                    nach dem Verkauf sofort tilgen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Wann lohnt sich eine Zwischenfinanzierung?</h2>
                <p>
                    Wenn du gerade im Begriff bist, eine Immobilie zu verkaufen, und bereits eine neue in Aussicht hast,
                    ist eine Zwischenfinanzierung in vielen Fällen der richtige Weg. Denn andernfalls stündest du beim
                    Verkauf unter erheblichem Zeitdruck und wärst gezwungen, möglichst rasch ein Angebot wahrzunehmen.
                    Wie wir aus Erfahrung wissen, lassen sich bei einem Immobilienverkauf aber oft bessere Preise
                    erzielen, wenn man etwas Zeit verstreichen lässt. Auch wenn du bei der Finanzierung des neuen
                    Objektes auf eine Finanzierung mit längerer Laufzeit verzichten möchtest, ist eine
                    Zwischenfinanzierung hilfreich. Denn mit ihrer Hilfe erhältst du in Summe attraktivere Konditionen
                    bei deinem Kreditinstitut. Ein kleiner Tipp zum Schluss: Wenn es darum geht, die gegenseitigen
                    Auswirkungen von Kreditsumme, Laufzeit und Zinsen zu ermitteln, dann hilft unser{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>
                    !
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>

            <BreadcrumbList page={"zwischensfinanzierung"}></BreadcrumbList>
            <ArticleStructuredData page={"zwischensfinanzierung"} heading={"Zwischenfinanzierung – Ein Überblick"} />
        </Layout>
    );
};

export default Zwischensfinanzierung;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.zwischensfinanzierung"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
